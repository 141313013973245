export const GoogleId = 1
export const GoogleName = 'Google Ads'
export const MicrosoftId = 2
export const MicrosoftName = 'Microsoft Ads'
export const FacebookId = 3
export const FacebookName = 'Facebook'
export const BasisId = 17
export const BasisName = 'Basis'
export const TradeDeskId = 18
export const TradeDeskName = 'The Trade Desk'

export const PartnerName = (id) => {
  switch (id) {
    case '0':
    case 0:
      return 'Fluency'
    case '1':
    case GoogleId:
      return GoogleName
    case '2':
    case MicrosoftId:
      return MicrosoftName
    case '3':
    case FacebookId:
      return FacebookName
    case '4':
    case 4:
      return 'Snapchat'
    case '5':
    case 5:
      return 'Waze Ads'
    case '6':
    case 6:
      return 'Amazon Ads'
    case '7':
    case 7:
      return 'DoubleClick'
    case '8':
    case 8:
      return 'DDC RTB'
    case '9':
    case 9:
      return 'Pinterest'
    case 10:
      return 'Qb'
    case 11:
      return 'Shopify'
    case '17':
    case BasisId:
      return BasisName
    case TradeDeskId:
      return TradeDeskName
    default:
      return 'Partner'
  }
}

export default PartnerName
